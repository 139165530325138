<template>
  <vs-row>
    <section class="content">
      <div class="box box-primary" style="padding:10px;position: relative">
        <div id="root">
          <form name="adminForm" method="post" enctype="multipart/form-data">
            <input id="cassielId" name="cassielId" v-bind:value="cassielid" type="hidden"/>
          </form>
          <table style="width:100%; border: none">
            <tr>
              <th colspan="2"><h3><u>Competitor Info:</u></h3></th>
              <th colspan="2"><h3><u>Competition Info:</u></h3></th>
            </tr>
            <tr>
              <td style="width: 130px;"><b>First Name: </b></td>
              <td>{{ configuration.competitor.firstName }}</td>
              <td style="width: 130px"><b>Name:</b></td>
              <td>{{ configuration.competition.name }}</td>
            </tr>
            <tr>
              <td style="width: 130px"><b>Last Name: </b></td>
              <td>{{ configuration.competitor.lastName }}</td>
              <td style="width: 130px"><b>Start Date:</b></td>
              <td>{{ configuration.competition.startDate }}</td>
            </tr>
            <tr>
              <td style="width: 130px"><b>Email: </b></td>
              <td>{{ configuration.competitor.email }}</td>
              <td style="width: 130px"><b>End Date:</b></td>
              <td>{{ configuration.competition.endDate }}</td>
            </tr>
            <tr>
              <td style="width: 130px"><b>user start date competition: </b></td>
              <td v-if="configuration.CompetitorCompetition.startDate">
                {{ configuration.CompetitorCompetition.startDate }}
              </td>
              <td v-if="!configuration.CompetitorCompetition.startDate">
                {{ configuration.CompetitorCompetition.startDate }}
              </td>
              <td style="width: 130px"><b>Number of Stage:</b></td>
              <td>{{ configuration.competition.numberOfStage }}</td>
            </tr>
          </table>
          <br>
        </div>
      </div>
    </section>
    <section class="content">
      <div v-for="(data, key) in configuration.data" :key="key" class="box box-primary"
           style="padding:10px;position: relative; direction: rtl; text-align: right">
        <h3 style="color: #3c8dbc"><u>{{ configuration.stages[i].name }}</u> <span
            style="font-size: 12px; color: black; ">  (<label>تاريخ البدئ: </label> {{
            configuration.stages[i].startDate
          }}, <label>تاريخ الإنتهاء: </label> {{ configuration.stages[i].endDate }})</span>
        </h3>
        <div v-for="(field, index) in data" :key="index">
          <div v-for="(stageForm, keyForm) in configuration.stages[i].form" :key="keyForm" style="display: none">
            <div v-for="(stageField, keyField) in stageForm" :key="keyField">
              <p v-if="stageField.name== index" :set="label = stageField.label"></p>
              <p v-if="stageField.name== index" :set="column = keyForm"></p>
              <p v-if="stageField.name== index" :set="type = stageField.fieldType"></p>
            </div>
          </div>
          <!--        </div>-->
          <div class="card-body" style="padding: 0">
            <div style="margin: auto; padding: 10px 0 0 30%;"
                 v-if="index != 'approve' && index != 'stageId' && index != 'competitionId' && index != 'note' && index != 'score' && index !='endDate' ">
              <div v-if=" type != 'CassielLinkField' && type != 'CassielVideoUploader'"><label>{{ label }}: </label>
                {{ field }}
              </div>

              <div v-else-if="type == 'CassielLinkField'"><label>{{ label }}: </label> <a v-bind:href="field"
                                                                                          style="text-decoration: underline;"
                                                                                          target="_blank">{{
                  field
                }}</a>

                <blockquote v-if="field.includes('tiktok')" class="tiktok-embed" v-bind:cite="field "
                            v-bind:data-video-id="field.split('/')[field.split('/').length - 1].split('?')[0]"
                            style="max-width: 605px;min-width: 325px;">
                  <section></section>
                </blockquote>
              </div>
              <div v-else-if="type == 'CassielVideoUploader'">
                <label>{{ label }}: </label>
                <div style="text-align: center">
                  <video width="320" height="240" controls>
                    <source v-bind:src="field" type="video/mp4">
                    <source v-bind:src="field" type="video/ogg">
                  </video>
                </div>
                <br>
                <a v-bind:href="field" style="display: inherit; font-size: 18px" download>Download
                  <i class="fa fa-download"></i></a>
              </div>
            </div>

            <div v-else-if="index !='note' && ((index == 'approve'  && field == false) || index == 'score')"
                 id="box-success-approve-score" class="box box-success"
                 style="padding:10px;position: relative; margin-top: 50px">
              <div class="row" align="right">
                <div v-if="index == 'score'" :set="scoreField=1">
                  <div class="col-12">
                    <input name="score" v-bindid="'score'+i" v-bind:value="field">
                    <button class="btn btn-primary" type="button" style="border-radius: 5px; margin-right: 5px"
                            @click="saveScore(i, configuration.CompetitorCompetition.id)">
                      save
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="index == 'approve' && field == false">
                <div class="col-12" id="ApproveDiv">
                  <froala :tag="'textarea'"
                          :config="config"
                          name="note"
                          id="Note"
                          v-bind:cassielId="cassielid">
                  </froala>
                  <br>
                  <button class="btn btn-success" type="button"
                          @click="approve(i, configuration.CompetitorCompetition.id)">
                    Approve
                  </button>
                </div>
              </div>
              <div v-if="index == 'endDate'">
                <label style="float: right"> تاريخ الإنتهاء:</label>
                <p style="margin-right: 80px; text-orientation: inherit"> {{ field }}</p>
              </div>
            </div>
          </div>
        </div>
        <p :set="i= i+1"></p>
      </div>
    </section>
  </vs-row>
</template>
<style>

.content {
  width: 100%;
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.box.box-primary {
  border-top-color: #3c8dbc;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

a {
  color: #3c8dbc;
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

.box.box-success {
  border-top-color: #00a65a;
}

.btn-success {
  background-color: #00a65a;
  border-color: #00a65a;
  border-radius: 5px;
}

table {
  background-color: transparent;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

h3 {
  font-family: 'Source Sans Pro', sans-serif;
}

h3 {
  font-size: 24px;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

u {
  text-decoration: underline;
}

h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.required::after {
  content: "*";
  color: red;
}

td, th {
  padding: 8px;
}

.tab-content {
  height: fit-content !important;
}

.row {
  margin: 10px 0;
}
</style>


<!--function approve( stage, id) {-->
<!--  $.ajax({-->
<!--    type: "POST",-->
<!--    url: "/cassiel-backend-api/contest/approve",-->
<!--    data: {-->
<!--      competitor: '{{ mainEntityObject.competitor.id }}',-->
<!--      stage: stage,-->
<!--      note: $("#Note").val(),-->
<!--      id: id,-->
<!--      competitior: '{{ mainEntityObject.competitor.id }}'-->
<!--    },-->
<!--    dataType: "json",-->
<!--    success: function (data) {-->
<!--      if (data.error === false) {-->
<!--        if (scoreField === 0) {-->
<!--          $("#box-success-approve-score").hide();-->
<!--        }-->
<!--        $("#ApproveDiv").hide();-->
<!--        swal({-->
<!--          title: "Approve!",-->
<!--          text: "This stage has been approved successufly.",-->
<!--          type: "success"-->
<!--        });-->
<!--      }-->

<!--    },-->
<!--    error: function (result) {-->
<!--    }-->
<!--  });-->
<!--}-->

<!--function saveScore(stage, id) {-->

<!--  $.ajax({-->
<!--    type: "POST",-->
<!--    url: "/cassiel-backend-api/contest/score",-->
<!--    data: {-->
<!--      score: $('#score' + stage).val(),-->
<!--      stage: stage,-->
<!--      id: id-->
<!--    },-->
<!--    dataType: "json",-->
<!--    success: function (data) {-->
<!--      if (data.error === false) {-->
<!--        swal({-->
<!--          title: "Save Score!",-->
<!--          text: "The score has been saved successufly.",-->
<!--          type: "success"-->
<!--        });-->
<!--      }-->

<!--    },-->
<!--    error: function (result) {-->
<!--    }-->
<!--  });-->
<!--}-->
<!--</script>-->

<script>
export default {
  name: "cassielcontestcompetitor",
  mixins: [],
  props: {},
  data: () => ({
    i: 0,
    scoreField: 0,
    configuration: [],
    cassielid: '',
    key: '',
    direction: '',
    config: {},
    model: 'Edit Your Content Here!',
    label: '',
    type: '',
    column: ''
  }),
  mounted: function () {
    this.direction = process.env.VUE_APP_FROALA_DIRECTION;
    this.key = process.env.VUE_APP_FROALA_KEY;
    this.config = {
      key: this.key,
      direction: this.direction,
      events: {
        'froalaEditor.initialized': function () {
          console.log('initialized')
        }
      }
    }
    let Script = document.createElement('script')
    Script.setAttribute('src', 'https://www.tiktok.com/embed.js')
    document.head.appendChild(Script)
    const baseFormConfigurationUrl = this.$apiList.baseFormConfigurationAPI;
    let configurationUrl = baseFormConfigurationUrl + '/contest/competitors';
    const token = this.$store.getters['auth/getToken'];
    let headers = {};
    if (token) {
      headers['X-AUTH-TOKEN'] = token;
    }
    if (this.$route.query.cassielId != undefined) {
      this.cassielid = this.$route.query.cassielId
    }
    var bodyFormData = new FormData();
    Object.entries(this.$route.query).forEach(([key, value]) => {
      bodyFormData.append(key, value);
    });
    this.$http({
      'url': configurationUrl,
      'method': 'POST',
      'data': bodyFormData,
      'headers': headers
    }).then(response => {
      this.configuration = response.data.configuration;
    });
  },
  components: {},
  methods: {
    approve: function (stage, id) {
      const token = this.$store.getters['auth/getToken'];
      let headers = {};
      if (token) {
        headers['X-AUTH-TOKEN'] = token;
      }
      let $ = require("jquery");
      var bodyFormData = new FormData();
        bodyFormData.append('id', id);
        bodyFormData.append('competitor', this.configuration.competitor.id);
        bodyFormData.append('stage', stage);
        bodyFormData.append('note', $("#Note").val());

      this.$http({
        'url': process.env.VUE_APP_CMS_BACKEND_BASE_URL + '/cassiel-backend-api/contest/approve',
        'method': 'POST',
        'data': bodyFormData,
        'headers': headers
      }).then(response => {
        if (response.data.error === false) {
          if (this.scoreField === 0) {
            $("#box-success-approve-score").hide();
          }
          $("#ApproveDiv").hide();
          this.$swal({
            title: "Approved!",
            icon: 'success',
            text: "This stage has been approved successufly",
            confirmButtonText: "yes",
            showCancelButton: false,
            confirmButtonColor: "#008080"
          })
        }
      });
    },
    saveScore: function (stage, id) {
      const token = this.$store.getters['auth/getToken'];
      let headers = {};
      if (token) {
        headers['X-AUTH-TOKEN'] = token;
      }
      let $ = require("jquery");
      var bodyFormData = new FormData();
        bodyFormData.append('id', id);
        bodyFormData.append('competitor', this.configuration.competitor.id);
        bodyFormData.append('stage', stage);
        bodyFormData.append('score', $('#score' + stage).val());

      this.$http({
        'url': process.env.VUE_APP_CMS_BACKEND_BASE_URL + '/cassiel-backend-api/contest/score',
        'method': 'POST',
        'data': bodyFormData,
        'headers': headers
      }).then(response => {
        if (response.data.error === false) {
          this.$swal({
            title: "Save Score!",
            icon: 'success',
            text: "The score has been saved successufly",
            confirmButtonText: "yes",
            showCancelButton: false,
            confirmButtonColor: "#008080"
          })
        }
      });
    },
  }
};
</script>